import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
// import '../../styles/index.css';

const IndexBannerCarouselMobile = (props) => {
    const [autoStartState, setAutoStartState] = useState(false);
    const [showArrowsState, setShowArrowsState] = useState(false);
    const [bannerFocusedItemState, setBannerFocusedItemState] = useState(0);

    const {
        width = '100%',
        // showArrows = true,
        showIndicators = true,
        children,
        ...restProps
    } = props;

    function prepareCarousel() {
        setAutoStartState(false);
        setAutoStartState(true);
    }

    useEffect(() => {
        console.log(props.props.showArrows)
        setShowArrowsState(props.props.showArrows)
        prepareCarousel();
    }, [props])

    return (
        <Carousel
            width={width}
            showArrows={showArrowsState}
            showIndicators={showIndicators}
            autoPlay={autoStartState}
            showStatus={false}
            showThumbs={false}
            emulateTouch={false}
            swipeable={false}
            infiniteLoop={true}
            {...restProps}

            renderArrowPrev={(clickHandler, hasPrev, labelPrev) =>
                <>
                    {/* <div class={"absolute top-0 left-0 z-20 w-[154px] h-full index-banner-overlay-left" + (bannerFocusedItemState == 0 || showArrowsState == false ? ' transpirant' : '')}>

                    </div> */}
                    {/* <button type="button" className={"absolute top-0 left-0 z-20 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none index-banner-button-frame" + (bannerFocusedItemState == 0 || showArrowsState == false ? ' transpirant' : '')} onClick={clickHandler}> */}
                    <div className={'absolute inline-flex items-center justify-center w-10 h-10 rounded-full index-banner-button transpirant' + (bannerFocusedItemState == 0 || showArrowsState == false ? ' transpirant' : '')}>
                        <img className='p-[10px]' src='/svgs/index-horizontalScroll-arrowLeft.svg'></img>
                    </div>
                    {/* </button> */}
                </>
            }

            renderArrowNext={(clickHandler, hasNext, labelNext) =>
                <>
                    {/* <div class={"absolute top-0 right-0 z-20 w-[154px] h-full index-banner-overlay-right" + (bannerFocusedItemState + 1 >= children.length || showArrowsState == false ? ' transpirant' : '')}>

                    </div> */}
                    {/* <button type="button" className={"absolute top-0 right-0 z-20 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none index-banner-button-frame" + (bannerFocusedItemState + 1 >= children.length || showArrowsState == false ? ' transpirant' : '')} onClick={clickHandler}> */}
                    <div className={'absolute inline-flex items-center justify-center w-10 h-full rounded-full index-banner-button transpirant' + (bannerFocusedItemState + 1 >= children.length || showArrowsState == false ? ' transpirant' : '')}>
                        <img className='p-[10px]' src='/svgs/index-horizontalScroll-arrowRight.svg'></img>
                    </div>
                    {/* </button> */}
                </>
            }

            onChange={(index) => setBannerFocusedItemState(index)}
        >
            {children}
        </Carousel>
    );
};

export default IndexBannerCarouselMobile;
